.button__label {
  text-shadow: none !important;
}

.button {
  @apply normal-case font-normal text-white text-lg inline-block px-4 py-2 inline-flex justify-center leading-none border rounded no-underline button text-center bg-blue border-blue;

  &:hover {
    @apply text-white bg-blue-light;
  }
}

.tribe-events-button,
#tribe-events .tribe-events-button {
  @apply .button #{!important};

  &:hover {
    @apply text-white bg-blue-light #{!important};
  }
}
