.venues__title {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.76) 77%
  );

  span {
    @apply absolute pin-b pin-l py-1 px-2;
  }
}

.venues__transitive-link:hover,
.venues__transitive-link:focus,
.venues__transitive-link:active {
  @apply shadow-lg;
}
