.datepicker.dropdown-menu {
  z-index: 100 !important;
}

.single-tribe_events .tribe-events-event-meta {
  @apply bg-grey-lightest;
}

#tribe-bar-views-toggle {
  @apply flex;
}

.tribe-events-list .tribe-events-loop .tribe-event-featured {
  @apply bg-white text-grey-darkest;
}

#tribe-events-footer {
  @apply mt-4 mb-4;
}

#tribe-events .tribe-events-content p,
.tribe-events-before-html p,
.tribe-events-after-html p {
  @apply leading-normal;
}

.tribe-events-list-separator-month {
  @apply mb-0 mt-0;
}

.tribe-events-event-meta {
  > * + * {
    @apply ml-5;
  }

  @include mq($until: md) {
    > * + * {
      @apply mt-5 ml-0;
    }
  }

  .meta-details {
    @include mq($from: md) {
      flex-grow: 1;
      min-width: 150px;
    }
  }
}

.tribe-events-list .type-tribe_events {
  margin-left: 0 !important;
  border-bottom: 0;
}

.type-tribe_events + .type-tribe_events {
  border-top: 1px solid #ddd;
}

.tribe-events-event-meta .tribe-events-meta-group {
  padding: 0;
}
