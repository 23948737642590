.menu-item {
  @apply list-reset block mt-4 mr-4;
}

.menu-item a {
  @apply block no-underline text-white;
}

@screen lg {
  .menu-item {
    @apply inline-block mt-0;
  }
}

.main-navigation {
  @include mq($until: lg) {
    transition: transform 0.3s ease;
    transform: translateX(100%);

    &.is-open {
      transform: translateX(0);
    }
  }
}
