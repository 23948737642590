.grid-3-3 {
  display: grid;
  gap: 1.25rem;

  @include mq($from: md) {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}

.grid-2-2 {
  display: grid;
  gap: 1.25rem;

  @include mq($from: md) {
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
  }
}
