.sponsor-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 150px);
  grid-gap: 30px;

  * {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include mq($until: md) {
    grid-template-columns: repeat(1, 1fr);
  }
}
