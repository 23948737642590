.space-t {
  > * + * {
    @apply mt-5;
  }
}

.space-t-deep {
  * + * {
    @apply mt-5;
  }
}

.space-l {
  > * + * {
    @apply ml-5;
  }
}

.space-l-deep {
  * + * {
    @apply ml-5;
  }
}
