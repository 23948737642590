.hero__image {
  &::after {
    @apply bg-blue absolute pin-y pin-x opacity-25;

    content: '';
    mix-blend-mode: darken;
  }

  img {
    object-fit: cover;
  }
}

.hero__text {
  > * + * {
    @apply mt-4;
  }

  * {
    @apply text-white;

    text-shadow: 1px 1px 1px config('colors.black'),
      1.2px 1.2px 1px config('colors.grey-darkest');
  }

  a {
    @apply text-white;

    &:hover {
      @apply text-white;
    }
  }
}

.hero__callouts {
  @apply flex-col items-stretch;

  > * + * {
    @apply mt-3;
  }

  @include mq($from: lg) {
    @apply flex-row;

    > * + * {
      @apply mt-0 ml-2;
    }
  }
}
