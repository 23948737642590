html {
  @apply overflow-y-scroll overflow-x-hidden opacity-0 font-sans text-grey-darkest leading-normal;

  font-size: 16px;

  &.fonts-loaded {
    @apply opacity-100;
  }
}

html,
body {
  @apply h-full bg-grey-lighter;
}

strong {
  font-weight: 600;
}

.container {
  @apply px-4 max-w-xl;

  .front-page & {
    max-width: 1200px;
  }
}

a {
  @apply text-blue inline-flex items-center;

  &:hover {
    @apply underline;
  }
}
