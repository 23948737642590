.flip-clock {
  font-size: 14px;

  @include mq($until: md) {
    font-size: 2vw;

    .flip-clock-group .flip-clock-label {
      font-size: 12px;
    }
  }
}
